import languages from '@cospired/i18n-iso-languages'

export default {

  data() {
    return {
      i18nLangs: {},
    }
  },
  async created() {
    this.setLanguagesLocale(this.$store.state.locale)
  },
  watch: {
    '$store.state.locale': function (newLocale) { this.setLanguagesLocale(newLocale) },
  },

  methods: {
    async setLanguagesLocale(locale) {
      languages.registerLocale(await import(`@cospired/i18n-iso-languages/langs/${locale}.json`))
      this.i18nLangs = languages.getNames(locale)
    },
    getLanguageName(languageCode) {
      return this.i18nLangs[languageCode]
    },
    getLanguageList() {
      return this.i18nLangs
    },
  },
}
