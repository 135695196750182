<template>
  <div v-if="hasFields">
    <h2 v-if="mainTitle" class="mb-4" v-text="mainTitle" />
    <v-row v-for="page in pages" :key="page" class="mb-4">
      <template v-for="field in dynamicForm.fields.filter(f => f.page === page)">
        <v-col v-if="field.type === 'info-title'" :key="field.id" cols="12">
          <h3 class="mb-3" v-text="field.text" />
        </v-col>
        <v-col v-else-if="field.type === 'info-paragraph'" :key="field.id" cols="12">
          <p class="mb-3" v-text="field.text" />
        </v-col>
        <v-col v-else :key="field.id"
               cols="12" :sm="layout === 'vertical' ? '12' : '3'" class="py-1 px-3"
        >
          <component
            :is="getFieldComponent(field)"
            v-model="form[field.id]" outlined dense :error-messages="getFieldErrorsDynamic(field.id)"
            v-bind="getFieldProps(field)" :disabled="field.isStatic && !!data.length" @blur="$v.form[field.id].$touch()"
            @inputCode="$v.form[field.id].code.$touch(); form[field.id].code = $event"
            @inputNumber="$v.form[field.id].number.$touch(); form[field.id].number = $event"
          >
            <template v-if="field.type === 'radio'">
              <v-radio v-for="option in field.options" :key="option.id" :label="option.label" :value="option.id" />
            </template>
          </component>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import { VTextField, VTextarea, VSelect, VRadioGroup, VSwitch, VCheckbox } from 'vuetify/lib'
import { DateTime } from 'luxon'
import formUtils from '@/utils/mixins/formUtils'
import i18nCountries from '@/utils/mixins/i18nCountries'
import i18nLanguages from '@/utils/mixins/i18nLanguages'

export default {
  name: 'EditUserForm',
  components: {
    DateField: () => import('@/components/formFields/DateField'),
    PhoneField: () => import('@/components/formFields/PhoneField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    FileField: () => import('@/components/formFields/FileField'),
    VTextField,
    VTextarea,
    VSelect,
    VRadioGroup,
    VSwitch,
    VCheckbox,
  },
  mixins: [formUtils, i18nCountries, i18nLanguages],
  props: {
    mainTitle: { type: String, default: null },
    layout: { type: String, default: 'vertical' },
    data: { type: Array, default: () => [] },
  },
  data: () => ({ form: null, file: null }),
  computed: {
    ...mapGetters({ dynamicForm: 'dynamicForm/data' }),
    ...mapGetters({ project: 'project/data' }),
    pages: ({ dynamicForm }) => [...new Set(dynamicForm.fields.map(f => f.page))],
    dynamicFields: () => [
      { type: 'text', component: 'v-text-field' },
      { type: 'select', component: 'v-select' },
      { type: 'date', component: 'date-field' },
      { type: 'email', component: 'v-text-field' },
      { type: 'phone', component: 'phone-field' },
      { type: 'switch', component: 'v-switch' },
      { type: 'radio', component: 'v-radio-group' },
      { type: 'checkbox', component: 'v-checkbox' },
      { type: 'image', component: 'image-field' },
      { type: 'file', component: 'file-field' },
    ],
    hasFields: ({ dynamicForm }) => !!dynamicForm?.fields?.length,
    fieldsFilter: ({ dynamicForm, hasFields }) => hasFields
      ? dynamicForm?.fields.filter(f => f.type !== 'info-title' && f.type !== 'info-paragraph') : null,
    dynamicValidation() {
      return this.hasFields ? Object.fromEntries(this.fieldsFilter
        .map(f => ([[f.id], {
          required: requiredIf(() => f.isRequired),
          ...(f.type === 'email' && { email }),
          ...(f.type === 'phone' && { code: { required }, number: { required } }),
        }]))) : null
    },
    availableLanguages() {
      return Object.entries(this.getLanguageList())
        .map(([languageCode, languageName]) => ({ text: languageName, value: languageCode }))
    },
  },
  async created() {
    this.timezone = this.project?.timezone
    this.form = this.hasFields ? Object.fromEntries(this.fieldsFilter
      .map(f => ([[f.id], this.getValueField(f)]))) : null
  },
  validations() {
    return { form: this.dynamicValidation }
  },
  methods: {
    getValueField(field) {
      const value = this.data.find(f => f.id === field.id)?.value
      if (field.type === 'phone') return value ?? { country: null, code: null, number: null }
      return value ?? null
    },
    getFieldComponent(field) {
      if (field.type === 'text') {
        return field.isMultiline ? 'v-textarea' : 'v-text-field'
      }
      return this.dynamicFields.find(f => f.type === field.type)?.component
    },
    getFieldErrorsDynamic(fieldId) {
      if (this.$v.form[fieldId].code?.$error) {
        return this.getFieldErrors(`${fieldId}.code`)
      }

      if (this.$v.form[fieldId].number?.$error) {
        return this.getFieldErrors(`${fieldId}.number`)
      }
      return this.getFieldErrors(fieldId)
    },
    getFieldProps(field) {
      const label = field.isRequired ? field.label : `${field.label} ${this.$t('common.optional')}`
      const propsByType = {
        date: { timezone: this.timezone },
        select: { items: this.getFieldOptions({ optionsType: field.optionsType, options: field.options }) },
      }
      return { label, ...propsByType[field.type] ?? {} }
    },
    getFieldOptions({ optionsType, options }) {
      return {
        custom: options?.map(o => ({ text: o.label, value: o.id })),
        countries: this.countryItems,
        languages: this.availableLanguages,
      }[optionsType] ?? []
    },
    parseForm() {
      return Object.fromEntries(Object.entries(this.form)
        .map(([key, value]) => DateTime.fromISO(value).isValid
          ? [key, DateTime.fromISO(value, { zone: this.timezone }).toJSDate()]
          : [key, value]))
    },
  },
}
</script>
